import { RouteConfig } from 'vue-router'
import CourseSidebar from '../components/sidebar/CourseSidebar.vue'

const courseRouter: RouteConfig[] = [
  {
    path: '/',
    redirect: 'course'
  },
  {
    path: 'course',
    name: 'home',
    components: {
      sidebar: CourseSidebar,
      default: () => import('../views/Dashboard.vue')
    }
  },
  {
    path: 'category',
    name: 'category',
    components: {
      sidebar: CourseSidebar,
      default: () => import('../views/Category.vue')
    }
  }
]

export default courseRouter
