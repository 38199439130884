
  import { Vue, Component } from 'vue-property-decorator'
  import { SidebarInterface } from '@/interface/BaseInterface'

  @Component
  export default class Sidebar extends Vue {
    menu: SidebarInterface[] = [
      {
        label: this.$t('courses'),
        route: { name: 'home' }
      },
      {
        label: this.$t('categories'),
        route: { name: 'category' }
      },
      {
        label: this.$t('questionBank'),
        route: { name: 'questionBank' }
      }
    ]
  }
