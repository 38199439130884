
  import { Vue, Component } from 'vue-property-decorator'
  import { SidebarInterface } from '@/interface/BaseInterface'

  @Component
  export default class CourseSidebar extends Vue {
    menu: SidebarInterface[] = [
      {
        label: this.$t('courses'),
        route: { name: 'home' }
      },
      {
        label: this.$t('categories'),
        route: { name: 'category' }
      },
      {
        label: this.$t('questionBank'),
        route: { name: 'questionBank' }
      }
    ]

    async init() {
      try {
        if (this.checkRole(['all', 'edit', 'remove'], 'COURSE')) {
          this.menu = [
            {
              label: this.$t('courses'),
              route: { name: 'home' }
            },
            {
              label: this.$t('categories'),
              route: { name: 'category' }
            },
            {
              label: this.$t('questionBank'),
              route: { name: 'questionBank' }
            }
          ]
        } else {
          this.menu = [
            {
              label: this.$t('courses'),
              route: { name: 'home' }
            }
          ]
        }
      } catch (error) {
        console.log(error)
      }
    }

    created() {
      this.init()
    }
  }
