import { RouteConfig } from 'vue-router'
import Siderbar from '../components/sidebar/Sidebar.vue'
import ChildLayout2 from '@/components/ChildLayout2.vue'

const courseDetailRouter: RouteConfig[] = [
  {
    path: ':id',
    components: {
      sidebar: Siderbar,
      default: ChildLayout2
    },
    children: [
      {
        path: '/',
        component: ChildLayout2,
        children: [
          {
            path: '/',
            name: 'course',
            component: () => import('../views/CourseDetail.vue')
          },
          {
            path: 'section/:sectionId',
            name: 'courseChild',
            component: () => import('../views/CourseDetail.vue')
          }
        ]
      },

      {
        path: 'member',
        name: 'courseMember',
        component: () => import('../views/CourseMember.vue')
      },
      {
        path: 'quiz',
        component: ChildLayout2,
        children: [
          {
            path: '/',
            name: 'listQuizzes',
            component: () => import('../views/quiz/ListQuizzes.vue')
          },
          {
            path: ':quizId',
            name: 'quizDetail',
            component: () => import('../views/quiz/QuizDetail.vue')
          },
          {
            path: ':quizId/edit',
            name: 'quizEdit',
            component: () => import('../views/quiz/QuizEdit.vue')
          },
          {
            path: ':quizId/edit/question',
            name: 'quizAddQuestion',
            component: () => import('../views/quiz/QuizEdit.vue')
          },
          // {
          //   path: ':quizId/preview',
          //   name: 'quizPreview',
          //   component: () => import('../views/quiz/QuizPreview.vue')
          // },
          {
            path: ':quizId/attempt/:attemptId',
            name: 'quizResult',
            component: () => import('../views/quiz/QuizResult.vue')
          },
          {
            path: ':quizId/attempt/:attemptId/user/:userId',
            name: 'quizResultByUser',
            component: () => import('../views/quiz/QuizResult.vue')
          }
        ]
      },
      {
        path: 'files',
        component: ChildLayout2,
        children: [
          {
            path: '/',
            name: 'files',
            component: () => import(`../views/files/ListFiles.vue`)
          }
        ]
      },
      {
        path: 'grades',
        component: ChildLayout2,
        children: [
          {
            path: '/',
            name: 'grades',
            component: () => import(`../views/grades/CourseGrades.vue`)
          }
        ]
      },
      {
        path: 'settings',
        component: ChildLayout2,
        children: [
          {
            path: '/',
            name: 'settings',
            component: () => import(`../views/CourseSettings.vue`)
          }
        ]
      },
      {
        path: 'quiz-review',
        component: ChildLayout2,
        children: [
          {
            path: '/',
            name: 'quizReview',
            component: () => import(`../views/quizReview/QuizReview.vue`)
          },
          {
            path: 'users',
            name: 'quizUserReview',
            component: () => import(`../views/quizReview/QuizUserReview.vue`)
          },
          {
            path: 'assign',
            name: 'quizReviewAssign',
            component: () => import(`../views/quizReview/QuizReviewAssign.vue`)
          },
          {
            path: 'report',
            name: 'quizReviewReport',
            component: () => import(`../views/quizReview/QuizReviewReport.vue`)
          }
        ]
      },
      {
        path: 'send-email',
        component: ChildLayout2,
        children: [
          {
            path: '/',
            name: 'sendEmail',
            component: () => import(`../views/email/SendEmail.vue`)
          }
        ]
      },
      {
        path: 'log',
        component: ChildLayout2,
        children: [
          {
            path: '/',
            name: 'quizLog',
            component: () => import(`../views/CourseLog.vue`)
          }
        ]
      }
    ]
  }
]

export default courseDetailRouter
