import BaseAvatar from './images/BaseAvatar.vue'
import BaseImage from './images/BaseAvatar.vue'
import BaseUser from './user/BaseUser.vue'
import BaseTitle from './title/BaseTitle.vue'
import BaseFilter from './filter/BaseFilter.vue'
import BasePopup from './popup/BasePopup.vue'
import BasePopupFullscreen from './popup/BasePopupFullscreen.vue'
import BaseSidebar from './sidebar/BaseSidebar.vue'
import BaseEditorPreview from './editor/BaseEditorPreview.vue'
import BasePagination from './pagination/BasePagination.vue'
import BaseUpload from './uploads/BaseUpload.vue'
import BaseAudio from './audio/BaseAudio.vue'
import BaseCalendar from './calendar/BaseCalendar.vue'

const components = {
  BaseAvatar,
  BaseImage,
  BaseUser,
  BaseTitle,
  BaseFilter,
  BasePopup,
  BaseSidebar,
  BaseEditorPreview,
  BasePagination,
  BaseUpload,
  BasePopupFullscreen,
  BaseAudio,
  BaseCalendar
}

export default components
