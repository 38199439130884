import { AttemptResultInterface } from './../../../../interface/BaseInterface'
import { SQuizResult } from './index'
import { ActionTree } from 'vuex'

const actions: ActionTree<SQuizResult, any> = {
  setAttemptResult({ commit }, data: AttemptResultInterface[]) {
    commit('SET_ATTEMPT_RESULT', data)
  },
  addCommentAttempt({ commit }, data: { _id: string; comment: string; grade?: number }) {
    commit('SET_COMMENT_ATTEMPT', data)
  }
}

export default actions
