import { find, has, includes } from 'lodash'
import moment from 'moment'

// const labelDayOfWeek = ['Chủ nhật', 'Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7']

export function dateFormatSort(time: number | string) {
  const date = new Date(time)
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}

export function dateFormatNameSort(time: number | string) {
  const a = new Date(time)
  const date = moment(a)
  // return date.format('dddd, MMMM Do YYYY, h:mm:ss a')
  return date.format('dddd, DD/MM/YYYY')
}

export function dateFormatName(time: number | string) {
  const a = new Date(time)
  const date = moment(a)
  return date.format('dddd, DD/MM/YYYY, h:mm:ss a')
}

export function dateFormat(time: number | string) {
  const date = new Date(time)
  return date.toLocaleString()
  // const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  // return `${date.getHours()}:${minutes} ${labelDayOfWeek[date.getDay()]}, ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}

export function dateFormatYear(time: number | string) {
  const date = new Date(time)
  return date.getFullYear()
}

export function timeLeft(time: number) {
  if (time <= 0) {
    return `00 : 00`
  }
  const seconds = Math.floor(time / 1000)
  let minutes: string | number = Math.floor(seconds / 60)
  let second: string | number = Math.floor(seconds % 60)
  minutes = minutes > 9 ? minutes : `0${minutes}`
  second = second > 9 ? second : `0${second}`
  return `${minutes} : ${second}`
}

export function time2String(time: number) {
  const minutes = Math.floor(time / 60)
  const second = Math.floor(time % 60)
  return `${minutes}:${second > 9 ? second : `0${second}`}`
}

const mineTypes: Record<string, string> = {
  'application/msword': 'download-word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'download-word',
  'application/excel': 'download-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'download-excel',
  'application/vnd.ms-excel': 'download-excel',
  'application/x-excell': 'download-excel',
  'application/x-msexcel': 'download-excel',
  'application/mspowerpoint': 'download-power-point',
  'application/vnd.ms-powerpoint': 'download-power-point',
  'application/pdf': 'download-pdf',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'download-power-point'
}

const mineType2: Record<string, string> = {
  xls: 'download-excel',
  xlsx: 'download-excel',
  xlsb: 'download-excel',
  xlt: 'download-excel',
  xltx: 'download-excel',
  xltm: 'download-excel',
  xlsm: 'download-excel',
  xml: 'download-excel',
  doc: 'download-word',
  docx: 'download-word',
  docm: 'download-word',
  dotx: 'download-word',
  dotm: 'download-word',
  docb: 'download-word',
  ppsx: 'download-power-point',
  ppsm: 'download-power-point',
  pps: 'download-power-point',
  ppam: 'download-power-point',
  ppt: 'download-power-point',
  pptx: 'download-power-point',
  pdf: 'download-pdf',
  png: 'download-img',
  jpg: 'download-img',
  jpeg: 'download-img',
  mp4: 'download-video',
  mov: 'download-video'
}

export function iconFileMineType(mineType: string) {
  if (includes(mineType, 'image')) {
    return 'download-img'
  } else if (includes(mineType, 'video')) {
    return 'download-video'
  }
  const result = find(mineType2, (value, key) => {
    return key === mineType
  })
  if (result) {
    return result
  } else if (has(mineTypes, mineType)) {
    return mineTypes[mineType]
  } else {
    return 'download-rarzip'
  }
}

// export function convertGrade(rightAnswers: number, type = 'listening') {
export function convertGrade(rightAnswers: number) {
  let grade = 0
  // if (type === 'listening') {
  if (rightAnswers < 2) {
    grade = 0
  } else if (3 <= rightAnswers && rightAnswers <= 4) {
    grade = 2
  } else if (5 <= rightAnswers && rightAnswers <= 6) {
    grade = 2.5
  } else if (7 <= rightAnswers && rightAnswers <= 8) {
    grade = 3
  } else if (9 <= rightAnswers && rightAnswers <= 10) {
    grade = 3.5
  } else if (11 <= rightAnswers && rightAnswers <= 12) {
    grade = 4
  } else if (13 <= rightAnswers && rightAnswers <= 14) {
    grade = 4.5
  } else if (15 <= rightAnswers && rightAnswers <= 18) {
    grade = 5
  } else if (19 <= rightAnswers && rightAnswers <= 22) {
    grade = 5.5
  } else if (23 <= rightAnswers && rightAnswers <= 26) {
    grade = 6
  } else if (27 <= rightAnswers && rightAnswers <= 29) {
    grade = 6.5
  } else if (30 <= rightAnswers && rightAnswers <= 32) {
    grade = 7
  } else if (33 <= rightAnswers && rightAnswers <= 34) {
    grade = 7.5
  } else if (35 <= rightAnswers && rightAnswers <= 36) {
    grade = 8
  } else if (37 <= rightAnswers && rightAnswers <= 38) {
    grade = 8.5
  } else if (39 <= rightAnswers) {
    grade = 9
  } else {
    grade = 0
  }
  // } else {
  //   if (rightAnswers < 4) {
  //     grade = 0
  //   } else if (4 <= rightAnswers && rightAnswers <= 5) {
  //     grade = 2.5
  //   } else if (6 <= rightAnswers && rightAnswers <= 7) {
  //     grade = 3
  //   } else if (8 <= rightAnswers && rightAnswers <= 9) {
  //     grade = 3.5
  //   } else if (10 <= rightAnswers && rightAnswers <= 12) {
  //     grade = 4
  //   } else if (13 <= rightAnswers && rightAnswers <= 14) {
  //     grade = 4.5
  //   } else if (15 <= rightAnswers && rightAnswers <= 18) {
  //     grade = 5
  //   } else if (19 <= rightAnswers && rightAnswers <= 22) {
  //     grade = 5.5
  //   } else if (23 <= rightAnswers && rightAnswers <= 26) {
  //     grade = 6
  //   } else if (27 <= rightAnswers && rightAnswers <= 29) {
  //     grade = 6.5
  //   } else if (30 <= rightAnswers && rightAnswers <= 32) {
  //     grade = 7
  //   } else if (33 <= rightAnswers && rightAnswers <= 34) {
  //     grade = 7.5
  //   } else if (35 <= rightAnswers && rightAnswers <= 36) {
  //     grade = 8
  //   } else if (37 <= rightAnswers && rightAnswers <= 38) {
  //     grade = 8.5
  //   } else if (39 <= rightAnswers) {
  //     grade = 9
  //   } else {
  //     grade = 0
  //   }
  // }
  return grade
}
