
  import { Component, Vue } from 'vue-property-decorator'
  import { namespace } from 'vuex-class'
  import { UserInfoInterface } from '@/interface/BaseInterface'

  const auth = namespace('tAuth')

  @Component({})
  export default class HeaderMenu extends Vue {
    @auth.State('user') user!: UserInfoInterface
    @auth.Action('logout') logout!: () => void

    handleCommand(command: string) {
      if (command === 'profile') {
        console.log(command)
      } else if (command === 'logout') {
        this.logout()
        this.$router.push({ name: 'login' })
      }
    }
  }
