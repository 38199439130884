import { ActionTree } from 'vuex'
import { SBase, SPopupBase } from '.'

const actions: ActionTree<SBase, unknown> = {
  setOpenPopup({ commit }, data: SPopupBase) {
    commit('SET_OPEN_POPUP', data)
  }
}

export default actions
