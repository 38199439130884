import { CourseInterface, ModuleInterface, ModuleType } from './../../../../interface/BaseInterface'
import { MutationTree } from 'vuex'
import { SCourseDetail } from '.'
import { assign } from 'lodash'

const mutations: MutationTree<SCourseDetail> = {
  SET_FILE_PREVIEW: (state, data: ModuleInterface) => {
    state.filePreview = data
  },
  RESET_FILE_PREVIEW: state => {
    state.filePreview = {
      _id: '',
      courseId: '',
      sectionId: '',
      name: '',
      description: '',
      module_type: ModuleType.ATTACHMENT
    }
  },
  SET_COURSE_ACTIVE: (state, data: CourseInterface) => {
    state.courseActive = assign(data, {})
  }
}

export default mutations
