import { CategoryInterface } from './../../../interface/BaseInterface'
import request from '@/utils/request'
import { BaseApiInterface } from './../../../interface/BaseApiInterface'

export default class CategoryApi implements BaseApiInterface<CategoryInterface> {
  protected prefix = 'category'
  async all(params?: Record<string, any>): Promise<CategoryInterface[]> {
    try {
      const result = await request.get(`${this.prefix}`, {
        params: params
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  getById(id: string): Promise<CategoryInterface> {
    return request.get(`${this.prefix}/${id}`)
  }
  async create(params?: Record<string, any>): Promise<any> {
    try {
      const result = await request.post(`${this.prefix}`, params)
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async update(id: string, params?: Record<string, any>): Promise<any> {
    try {
      const result = await request.put(`${this.prefix}/${id}`, params)
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async delete(id: string): Promise<any> {
    return request.delete(`${this.prefix}/${id}`)
  }
}
