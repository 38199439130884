import { CourseFormat } from './interface/BaseInterface'

export const API_URL = process.env.VUE_APP_API_URL as string

export const MODE = process.env.NODE_ENV

export const courseFormat: CourseFormat[] = ['IELTS', 'SAT', 'SEED', 'EP']

export const fileAccept = [
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint',
  'text/plain',
  'application/pdf',
  '.doc',
  '.docx',
  'application/zip',
  'application/rar'
]

export const imageAccept = ['image/png', 'image/jpeg']

export const videoAccept = ['video/mp4', 'video/MP4V-ES', 'video/quicktime', 'video/x-flv', 'video/x-msvideo', 'video/x-ms-wmv']

export const audioAccept = ['audio/*']

export const labelDayOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

export const dayOfWeek = {
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday'
}
