import { SectionInterface } from './../../../interface/BaseInterface'
import request from '@/utils/request'

export default class SectionApi {
  protected prefix = 'section'
  protected coursePrefix = 'course'

  async all(courseId: string, params?: Record<string, any>): Promise<SectionInterface[]> {
    try {
      const result = await request.get(`${this.coursePrefix}/${courseId}/${this.prefix}`, {
        params: params
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getById(courseId: string, id: string): Promise<SectionInterface> {
    try {
      const result = await request.get(`${this.coursePrefix}/${courseId}/${this.prefix}/${id}`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async create(courseId: string, params?: Record<string, any>): Promise<any> {
    try {
      const result = await request.post(`${this.coursePrefix}/${courseId}/${this.prefix}`, params)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async update(courseId: string, id: string, params?: Record<string, any>): Promise<any> {
    try {
      const result = await request.put(`${this.coursePrefix}/${courseId}/${this.prefix}/${id}`, params)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async delete(courseId: string, id: string): Promise<any> {
    try {
      await request.delete(`${this.coursePrefix}/${courseId}/${this.prefix}/${id}`)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async removeModule(courseId: string, id: string, moduleId: string) {
    try {
      await request.delete(`${this.coursePrefix}/${courseId}/${this.prefix}/${id}/module/${moduleId}`)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async completeSection(courseId: string, id: string, type: 'start' | 'completed') {
    try {
      await request.put(`${this.coursePrefix}/${courseId}/${this.prefix}/${id}/${type === 'start' ? 'start' : 'complete'}`)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async resetStatusSection(courseId: string, id: string) {
    try {
      await request.put(`${this.coursePrefix}/${courseId}/${this.prefix}/${id}/status/reset`)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
