import { UserAnswerInterface } from '@/interface/BaseInterface'
import { Module } from 'vuex'
import actions from './actions'
import mutations from './mutations'

export interface SQuizResult {
  attemptResult: UserAnswerInterface[]
}

const state: SQuizResult = {
  attemptResult: []
}

const tQuizResult: Module<SQuizResult, unknown> = {
  namespaced: true,
  state,
  actions,
  mutations
}

export default tQuizResult
