import { includes, intersection, size } from 'lodash'
import { ModuleLabel, RoleType } from './../utils/role'
import { RoleModuleConfig } from '@/utils/role'
import { Vue, Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const tAuth = namespace('tAuth')
@Component
export default class RoleMixin extends Vue {
  @tAuth.Getter('getRole') rolesMixin!: RoleModuleConfig

  // checkRole(role: string[], module: ModuleLabel) {
  //   return ''
  // }
  checkRole(roles: RoleType[], module: ModuleLabel) {
    const role: RoleType[] | undefined = this.rolesMixin[module]
    if (!role) return false
    if (includes(role, 'all')) return true
    return size(intersection(role, roles)) > 0
  }
}
