import { assign, forEach, union } from 'lodash'
import { Role } from '@/interface/BaseInterface'

export type RoleScope = 'system' | 'course' | 'meeting'

export type RoleType = 'all' | 'view' | 'edit' | 'remove' | 'attempt' | 'review' | 'admin'

export type RoleLabel = 'ADMIN' | 'MEMBER' | 'TEACHER' | 'COURSE_ADMIN' | 'COURSE_TEACHER' | 'COURSE_MEMBER' | 'COURSE_ASSISTANT'

export type ModuleLabel = 'COURSE' | 'USERS' | 'MEETING' | 'COURSE_DETAIL' | 'QUIZ' | 'COURSE_MEMBERS' | 'QUESTION_BANK'

export type RoleConfigInterface = {
  [x in RoleLabel]: RoleModuleConfig
}

export type RoleModuleConfig = {
  [x in ModuleLabel]: RoleType[]
}

export const localRole: Record<string, RoleLabel> = {
  admin: 'ADMIN',
  member: 'MEMBER',
  system_teacher: 'TEACHER'
}

export const courseRole: Record<string, RoleLabel> = {
  admin: 'COURSE_ADMIN',
  course_teacher: 'COURSE_TEACHER',
  member: 'COURSE_MEMBER',
  course_assistant: 'COURSE_ASSISTANT'
}

export enum RoleName {
  SYSTEM_ADMIN = 'Admin',
  SYSTEM_MEMBER = 'Member',
  SYSTEM_TEACHER = 'Teacher',
  COURSE_ADMIN = 'Admin',
  COURSE_TEACHER = 'Teacher',
  COURSE_ASSISTANT = 'Assistant',
  COURSE_STUDENT = 'Member'
}

export const roleModuleConfig: RoleConfigInterface = {
  ADMIN: {
    COURSE: ['all'],
    USERS: ['all'],
    MEETING: ['all'],
    QUESTION_BANK: ['all'],
    COURSE_DETAIL: ['all'],
    QUIZ: ['all'],
    COURSE_MEMBERS: ['all']
  },
  MEMBER: {
    COURSE: ['view'],
    USERS: [],
    MEETING: [],
    QUESTION_BANK: [],
    COURSE_DETAIL: ['view'],
    QUIZ: ['view'],
    COURSE_MEMBERS: []
  },
  TEACHER: {
    COURSE: ['view'],
    USERS: [],
    MEETING: ['view'],
    QUESTION_BANK: [],
    COURSE_DETAIL: ['view'],
    QUIZ: ['view'],
    COURSE_MEMBERS: ['view']
  },
  COURSE_ADMIN: {
    COURSE: [],
    USERS: [],
    MEETING: [],
    QUESTION_BANK: [],
    COURSE_DETAIL: ['all'],
    QUIZ: ['all'],
    COURSE_MEMBERS: ['all']
  },
  COURSE_TEACHER: {
    COURSE: [],
    USERS: [],
    MEETING: [],
    QUESTION_BANK: [],
    COURSE_DETAIL: ['view', 'edit', 'remove', 'admin', 'review'],
    QUIZ: ['all'],
    COURSE_MEMBERS: ['view']
  },
  COURSE_MEMBER: {
    COURSE: [],
    USERS: [],
    MEETING: [],
    QUESTION_BANK: [],
    COURSE_DETAIL: ['view'],
    QUIZ: ['view'],
    COURSE_MEMBERS: []
  },
  COURSE_ASSISTANT: {
    COURSE: [],
    USERS: [],
    MEETING: [],
    QUESTION_BANK: [],
    COURSE_DETAIL: ['view', 'review'],
    QUIZ: ['view'],
    COURSE_MEMBERS: ['view']
  }
}

export function mapRole(roleA: RoleModuleConfig, roleB: RoleModuleConfig) {
  const result = assign({}, roleA)
  forEach(roleA, (value, key) => {
    //@ts-ignore
    result[key] = union(value, roleB[key])
  })
  return result
}

export function convertRole(role: Role | Role[], type: RoleScope = 'system'): RoleModuleConfig {
  let roleConfig: Record<string, RoleLabel> = {}

  let roles: RoleModuleConfig = roleModuleConfig['MEMBER']

  if (type === 'system') {
    roleConfig = localRole
    roles = roleModuleConfig['MEMBER']
  } else if (type === 'course') {
    roleConfig = courseRole
    roles = roleModuleConfig['MEMBER']
  }

  // const roles: RoleModuleConfig = roleModuleConfig['MEMBER']

  let myRole: RoleLabel[] = []
  if (typeof role === 'object') {
    const slug = (role as Role).slug || 'member'
    myRole.push(roleConfig[slug] || roleConfig['member'])
  } else {
    forEach(role as Role[], value => {
      const slug = value.slug || 'member'
      myRole.push(roleConfig[slug] || roleConfig['member'])
    })
  }

  myRole = union(myRole)
  forEach(myRole, value => {
    const rolesModule = roleModuleConfig[value]
    roles = mapRole(roles, rolesModule)
  })

  return roles
}
