import Vue from 'vue'
import Unicon from 'vue-unicons'

import {
  uniEllipsisV,
  uniListUl,
  uniApps,
  uniBookMedical,
  uniPlusCircle,
  uniFileQuestionAlt,
  uniCheckSquare,
  uniSquare,
  uniFileQuestion,
  uniClock,
  uniCheck,
  uniFileAlt,
  uniMeetingBoard
  // @ts-ignore
} from 'vue-unicons/src/icons'

Unicon.add([
  uniEllipsisV,
  uniListUl,
  uniApps,
  uniBookMedical,
  uniPlusCircle,
  uniFileQuestionAlt,
  uniCheckSquare,
  uniSquare,
  uniFileQuestion,
  uniClock,
  uniCheck,
  uniFileAlt,
  uniMeetingBoard
])

Vue.use(Unicon, {
  fill: 'currentColor'
})
