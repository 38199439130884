
  import { Component, Vue } from 'vue-property-decorator'
  import NavMenu from './sidebar/NavMenu.vue'
  import HeaderMenu from './header/HeaderMenu.vue'
  import { namespace } from 'vuex-class'

  const auth = namespace('tAuth')

  @Component({
    components: { NavMenu, HeaderMenu }
  })
  export default class Layout extends Vue {
    @auth.Getter('isLogin') isLogin!: boolean
    @auth.Action('getMyInfo') getMyInfo!: () => void

    isLoading = false

    async init() {
      try {
        if (this.isLogin) {
          this.isLoading = true
          await this.getMyInfo()
          this.isLoading = false
        } else {
          this.$router.push({ name: 'login' })
        }
      } catch (error) {
        this.$router.push({ name: 'login' })
      }
    }

    created() {
      this.isLoading = true
      setTimeout(() => {
        this.init()
      }, 1000)
    }
  }
