import { filter } from 'lodash'
import { MutationTree } from 'vuex'
import { SBase, SPopupBase } from '.'

const mutations: MutationTree<SBase> = {
  SET_POPUP: (state, data: string[]) => {
    state.popup = data
  },
  SET_OPEN_POPUP: (state, data: SPopupBase) => {
    if (data.isOpen) {
      state.popup.push(data.module)
    } else {
      state.popup = filter(state.popup, value => {
        return value !== data.module
      })
    }
  }
}

export default mutations
