import request from '@/utils/request'

export default class CourseFileApi {
  protected prefix = `files`

  async getCourseFile(id: string) {
    try {
      const result = await request.get(`${this.prefix}/${id}`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async createModuleFile(courseId: string, params: Record<string, any>) {
    try {
      const result = await request.post(`${this.prefix}/${courseId}`, params)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
