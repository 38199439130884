
  import { Component, Vue, Prop } from 'vue-property-decorator'

  @Component({ components: {} })
  export default class BaseAudio extends Vue {
    @Prop({ required: true, type: String }) src!: string

    id = Math.random()
      .toString(36)
      .substring(7)

    audio: HTMLAudioElement | null = null

    minutes = '00'
    seconds = '00'
    currentTime = 0
    duration = 0

    get getProcess() {
      if (this.duration != 0) {
        return (this.currentTime * 100) / this.duration
      }
      return 0
    }

    play() {
      if (this.audio) {
        this.audio.play()
        // this.audio.addEventListener('loadedmetadata', () => {
        //   console.log('audio', this.audio)
        //   console.log('audio 1', this.audio?.duration)
        //   this.displayDuration()
        // })
      }
    }

    pause() {
      if (this.audio) {
        this.audio.pause()
        this.audio.currentTime = 0
      }
    }

    displayDuration() {
      const audio = document.getElementById(this.id) as HTMLAudioElement
      // if (this.audio) {
      this.duration = audio.duration
      // }
    }

    reset() {
      this.minutes = '00'
      this.seconds = '00'
      this.currentTime = 0
    }

    updateCurrentTime() {
      this.currentTime = this.audio?.currentTime || 0
      this.$emit('currentTime', this.currentTime)
    }

    mounted() {
      this.audio = document.getElementById(this.id) as HTMLAudioElement
      this.audio.addEventListener('loadedmetadata', () => {
        this.displayDuration()
      })
      this.audio.addEventListener('timeupdate', this.updateCurrentTime)
    }

    beforeDestroy() {
      if (this.audio) {
        this.audio.removeEventListener('loadedmetadata', () => {
          this.displayDuration()
        })
        this.audio.removeEventListener('timeupdate', this.updateCurrentTime)
      }
      this.reset()
    }
  }
