import request from '@/utils/request'
import { sortBy } from 'lodash'
import { MeetingInterface } from './../../../interface/BaseInterface'

export default class MeetingApi {
  async list() {
    try {
      let data: MeetingInterface[] = []
      for (let i = 0; i < 100; i++) {
        data.push({
          _id: `id ${i}`,
          name: `name ${i}`,
          startTime: this.randomDate(new Date(), new Date(2021, 6, 30)).getTime(),
          meetingId: `meetingId ${i}`
        })
      }
      data = sortBy(data, 'startTime')
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async init() {
    try {
      await request.get(`meeting/init`)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  randomDate(start: Date, end: Date) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
  }
}
