import { PaginationInterface, UserInfoInterface } from './../../../interface/BaseInterface'
import request from '@/utils/request'
import { BaseApiInterface } from '@/interface/BaseApiInterface'

interface SearchParams {
  searchText?: string
  orderBy?: string
  orderDirection?: 'DESC' | 'ASC' | string
  page: number
  perPage: number
}
export default class MemberApi implements BaseApiInterface<UserInfoInterface> {
  protected prefix = 'user'
  async all(params?: Record<string, any>): Promise<PaginationInterface<UserInfoInterface>> {
    try {
      const result = await request.get(`${this.prefix}`, {
        params: params
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getById(id: string): Promise<UserInfoInterface> {
    try {
      const result = await request.get(`${this.prefix}/${id}`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async create(params?: Record<string, any>): Promise<any> {
    try {
      const result = await request.post(`${this.prefix}`, params)
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async update(id: string, params?: Record<string, any>): Promise<any> {
    try {
      const result = await request.put(`${this.prefix}/${id}`, params)
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async delete(id: string): Promise<any> {
    try {
      await request.delete(`${this.prefix}/${id}`)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getRoles(scope: 'course' | 'system' = 'system') {
    try {
      const result = await request.get(`${this.prefix}/roles`, {
        params: { scope: scope }
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updateProfile(params?: Record<string, any>) {
    try {
      const result = await request.put(`${this.prefix}/update/profile`, params)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async resetPassword(id: string) {
    try {
      const result = await request.put(`${this.prefix}/reset-password/${id}`)
      return Promise.resolve(result)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
