
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { words } from 'lodash'

  @Component
  export default class BaseAvatars extends Vue {
    @Prop({ required: true, type: String, default: '' }) avatar!: string
    @Prop({ required: false, type: Boolean, default: false }) isName!: boolean
    @Prop({ required: false, type: String, default: 'small' }) size!: string

    get getAvatar() {
      if (this.isName) {
        const arrWords = words(this.avatar)
        if (arrWords.length === 0) {
          return 'a'
        }
        return arrWords[arrWords.length - 1].charAt(0)
      }
      return this.avatar
    }

    get imgSize() {
      switch (this.size) {
        case 'ms':
          return 18
        case 's':
          return 24
        case 'xs':
          return 28
        case 'small':
          return 36
        case 'ssm':
          return 40
        case 'sm':
          return 50
        case 'medium':
          return 72
        case 'medium2':
          return 110
        case 'large':
          return 120
        default:
          return 36
      }
    }

    get imgClass() {
      const classType = {
        'be-avatar-square': true
        // 'be-avatar-round': this.type === 'round',
      }
      //@ts-ignore
      classType[`t-img-${this.size}`] = true
      return classType
    }
  }
