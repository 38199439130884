
  import { Component, Vue } from 'vue-property-decorator'
  import { SidebarInterface } from '@/interface/BaseInterface'

  @Component({ components: {} })
  export default class Sidebar extends Vue {
    menu: SidebarInterface[] = [
      {
        label: this.$t('meetings'),
        route: { name: 'meeting' },
        exact: true
      },
      {
        label: this.$t('schedules'),
        route: { name: 'meetingSchedules' },
        exact: true
      },
      // {
      //   label: this.$t('members'),
      //   route: { name: 'meetingUsers' },
      //   exact: true
      // },
      {
        label: this.$t('settings'),
        route: { name: 'meetingSetting' },
        exact: true
      },
      {
        label: this.$t('admin'),
        route: { name: 'meetingAdmin' },
        exact: true
      }
    ]
  }
