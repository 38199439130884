import request from '@/utils/request'
import { GradeBookInterface } from '@/interface/BaseInterface'

export class GradeBookApi {
  public async getGradeBooks(params: any): Promise<GradeBookInterface[]> {
    try {
      const result = await request.get(`grade-book/${params.courseId}`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
