import { PaginationInterface, QuestionInterface } from './../../../interface/BaseInterface'
import request from '@/utils/request'
import { BaseApiInterface } from './../../../interface/BaseApiInterface'

export default class QuestionApi implements BaseApiInterface<QuestionInterface> {
  protected prefix = 'question'
  async all(params?: Record<string, any>): Promise<PaginationInterface<QuestionInterface>> {
    try {
      const result = await request.get(`${this.prefix}`, {
        params: params
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(false)
    }
  }
  async getById(id: string): Promise<QuestionInterface> {
    try {
      const result = await request.get(`${this.prefix}/${id}`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async create(params?: Record<string, any>): Promise<any> {
    try {
      const result = await request.post(`${this.prefix}`, params)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async update(id: string, params?: Record<string, any>): Promise<any> {
    try {
      const result = await request.put(`${this.prefix}/${id}`, params)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async delete(id: string): Promise<any> {
    try {
      await request.delete(`${this.prefix}/${id}`)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
