import { Role } from './../../../../interface/BaseInterface'
import { UserInfoInterface } from '@/interface/BaseInterface'
import { MutationTree } from 'vuex'
import { SAuth } from '.'
import { convertRole, roleModuleConfig } from '@/utils/role'

const mutations: MutationTree<SAuth> = {
  UPDATE_TOKEN: (state, data: SAuth) => {
    state.token = data.token
    state.refreshToken = data.refreshToken
  },
  UPDATE_INFO: (state, data: UserInfoInterface) => {
    state.user = data || {}
    state.user.firstName = state.user.firstName || ''
    state.user.lastName = state.user.lastName || ''
    state.user.name = state.user.firstName + ' ' + state.user.lastName
    state.user.updatedAt = typeof state.user.updatedAt === 'string' ? new Date(state.user.updatedAt) : state.user.updatedAt
    state.user.createdAt = typeof state.user.createdAt === 'string' ? new Date(state.user.createdAt) : state.user.createdAt
    state.roles = roleModuleConfig['MEMBER']
    state.roles = convertRole(state.user.roles || { slug: '', name: '', _id: '' })
  },
  SET_ROLE: (state, role: Role) => {
    state.roles = roleModuleConfig['MEMBER']
    state.roles = convertRole(role || { slug: '', name: '', _id: '' })
  },
  SET_COURSE_ROLE: (state, role: Role | Role[]) => {
    state.courseRole = roleModuleConfig['COURSE_MEMBER']
    state.courseRole = convertRole(role || { slug: '', name: '', _id: '' }, 'course')
  },
  LOGOUT: state => {
    state.token = ''
    state.refreshToken = ''
    state.user = {
      _id: '',
      username: '',
      name: ''
    }
  }
}

export default mutations
