import { CourseInterface, ModuleInterface } from './../../../../interface/BaseInterface'
import { SCourseDetail } from './index'
import { ActionTree } from 'vuex'

const actions: ActionTree<SCourseDetail, any> = {
  setFilePreview({ commit }, data: ModuleInterface) {
    commit('SET_FILE_PREVIEW', data)
  },
  resetFilePreview({ commit }, data: ModuleInterface) {
    commit('RESET_FILE_PREVIEW', data)
  },
  setCourseActive({ commit }, data: CourseInterface) {
    commit('SET_COURSE_ACTIVE', data)
  },
  resetCourseActive({ commit }) {
    commit('SET_COURSE_ACTIVE', { _id: '', fullname: '' })
  }
}

export default actions
