import { Module } from 'vuex'
import actions from './actions'
import mutations from './mutations'

export interface SBase {
  popup: string[]
}

export interface SPopupBase {
  module: string
  isOpen: boolean
}

const state: SBase = {
  popup: []
}

const base: Module<SBase, unknown> = {
  namespaced: true,
  state,
  actions,
  mutations
}

export default base
