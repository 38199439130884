
  import { Vue, Component } from 'vue-property-decorator'
  import { SidebarInterface, Role, CourseInterface } from '@/interface/BaseInterface'
  import CourseApi from '@/modules/course/api/CourseApi'
  import getApi from '@/api'
  import { namespace } from 'vuex-class'
  import { MODE } from '@/config'

  const courseApi: CourseApi = getApi('course')
  const tCourseDetail = namespace('tCourseDetail')
  const tAuth = namespace('tAuth')

  @Component
  export default class Sidebar extends Vue {
    @tAuth.Action('setRoleCourse') setRoleCourse!: (role: Role) => void
    @tCourseDetail.State('courseActive') courseActive!: CourseInterface

    get menu() {
      // forEach(this.courseActive.sections, value => {
      //   courseChild.push({
      //     label: value.name,
      //     route: { name: 'courseChild', params: { id: this.$route.params.id, sectionId: value._id } },
      //     exact: false,
      //     child: true
      //   })
      // })

      const menu: SidebarInterface[] = [
        {
          label: this.$t('courses'),
          route: { name: 'course', params: { id: this.$route.params.id } },
          exact: true
        }
      ]

      if (this.checkRole(['view'], 'COURSE_MEMBERS')) {
        menu.push({
          label: this.$t('members'),
          route: { name: 'courseMember', params: { id: this.$route.params.id } },
          exact: true
        })
      }

      const menu2: SidebarInterface[] = [
        {
          label: this.$t('quizzes'),
          route: { name: 'listQuizzes', params: { id: this.$route.params.id } },
          exact: false
        },
        {
          label: this.$t('files'),
          route: { name: 'files', params: { id: this.$route.params.id } },
          exact: false
        },
        {
          label: this.$t('grades'),
          route: { name: 'grades', params: { id: this.$route.params.id } },
          exact: false
        }
      ]

      if (this.checkRole(['all'], 'COURSE_DETAIL')) {
        menu2.push({
          label: this.$t('settings'),
          route: { name: 'settings', params: { id: this.$route.params.id } },
          exact: false
        })

        menu2.push({
          label: 'Send Email',
          route: { name: 'sendEmail', params: { id: this.$route.params.id } },
          exact: false
        })
      }

      if (this.checkRole(['review'], 'COURSE_DETAIL')) {
        const quizReviewChildrens: SidebarInterface[] = []
        quizReviewChildrens.push({
          label: this.$t('review'),
          route: { name: 'quizReview', params: { id: this.$route.params.id } },
          exact: true,
          child: true
        })
        if (this.checkRole(['admin'], 'COURSE_DETAIL')) {
          quizReviewChildrens.push({
            label: this.$t('users'),
            route: { name: 'quizUserReview', params: { id: this.$route.params.id } },
            exact: false,
            child: true
          })
          quizReviewChildrens.push({
            label: this.$t('assignment'),
            route: { name: 'quizReviewAssign', params: { id: this.$route.params.id } },
            exact: false,
            child: true
          })
        }

        menu2.push({
          label: this.$t('quizReview'),
          route: { name: 'quizReview', params: { id: this.$route.params.id } },
          exact: false,
          childrens: quizReviewChildrens
        })

        menu2.push({
          label: this.$t('report'),
          route: { name: 'quizReviewReport', params: { id: this.$route.params.id } },
          exact: false,
          child: true
        })
      }

      if (MODE === 'development') {
        menu2.push({
          label: 'Logs',
          route: { name: 'quizLog', params: { id: this.$route.params.id } },
          exact: false
        })
      }

      return [...menu, ...menu2]
    }

    async init() {
      try {
        const role = await courseApi.getCourseRole(this.courseId)
        if (role) {
          this.setRoleCourse(role)
        }
      } catch (error) {
        console.log(error)
      }
    }

    created() {
      this.init()
    }
  }
