
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { UserInfoInterface } from '@/interface/BaseInterface'

  @Component
  export default class BaseUser extends Vue {
    @Prop({ required: true, type: Object }) user!: UserInfoInterface

    fullname() {
      let fullName = ''
      if (this.user.lastName) {
        fullName += this.user.lastName + ' '
      }
      if (this.user.firstName) {
        fullName += this.user.firstName
      }
      return fullName
    }
  }
