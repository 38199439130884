import { Vue, Component } from 'vue-property-decorator'

@Component
export default class GlobalMixin extends Vue {
  get courseId() {
    return this.$route.params.id
  }

  get quizId() {
    return this.$route.params.quizId
  }

  get questionId() {
    return this.$route.params.questionId
  }

  get attemptId() {
    return this.$route.params.attemptId
  }
}
