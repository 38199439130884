import request from '@/utils/request'

export default class QuizReview {
  protected prefix = 'course'

  async listTeachers(courseId: string) {
    try {
      const result = await request.get(`${this.prefix}/${courseId}/quiz-review/list-teacher`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async listMembersFree(courseId: string) {
    try {
      const result = await request.get(`${this.prefix}/${courseId}/quiz-review/list-free-member`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async listAssignStudents(courseId: string, teacherId: string) {
    try {
      const result = await request.get(`${this.prefix}/${courseId}/quiz-review/list-assign-member/${teacherId}`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async assignStudents(courseId: string, teacherId: string, studentIds: string[]) {
    try {
      const result = await request.post(`${this.prefix}/${courseId}/quiz-review/assign-student`, {
        teacherId,
        studentIds
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async removeStudents(courseId: string, teacherId: string, studentIds: string[]) {
    try {
      const result = await request.put(`${this.prefix}/${courseId}/quiz-review/remove-student`, {
        teacherId,
        studentIds
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getAssignAttempt(courseId: string, status: 'pending' | 'done' = 'pending') {
    try {
      const result = await request.get(`${this.prefix}/${courseId}/quiz-review/get-attempt`, {
        params: {
          status
        }
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getAssignAttemptByUser(courseId: string, userId: string, status: 'pending' | 'done' = 'pending') {
    try {
      const result = await request.get(`${this.prefix}/${courseId}/quiz-review/get-attempt/user/${userId}`, {
        params: {
          status
        }
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async submit(courseId: string, reviewId: string) {
    try {
      const result = await request.put(`${this.prefix}/${courseId}/quiz-review/submit/${reviewId}`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getMyReport(courseId: string, startDate: Date, endDate: Date, quizId?: string) {
    try {
      const result = await request.get(`${this.prefix}/${courseId}/quiz-review/report`, {
        params: {
          startDate: startDate.getTime(),
          endDate: endDate.getTime(),
          quizId
        }
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getUserReport(courseId: string, userId: string, startDate: Date, endDate: Date, quizId?: string) {
    try {
      const result = await request.get(`${this.prefix}/${courseId}/quiz-review/report/${userId}`, {
        params: {
          startDate: startDate.getTime(),
          endDate: endDate.getTime(),
          quizId
        }
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async removeReview(courseId: string, reviewId: string) {
    try {
      await request.delete(`${this.prefix}/${courseId}/quiz-review/attempt/${reviewId}`)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
