import { UserAnswerInterface } from './../../../../interface/BaseInterface'

import { MutationTree } from 'vuex'
import { SQuizResult } from '.'
import { findIndex } from 'lodash'

const mutations: MutationTree<SQuizResult> = {
  SET_ATTEMPT_RESULT: (state, data: UserAnswerInterface[]) => {
    state.attemptResult = data
  },
  SET_COMMENT_ATTEMPT: (state, data: { _id: string; comment: string; grade?: number }) => {
    const a = findIndex(state.attemptResult, value => {
      return value._id === data._id
    })
    if (a !== -1) {
      state.attemptResult[a].comment = data.comment
      state.attemptResult[a].grade = data.grade || 0
    }
  }
}

export default mutations
