export type IROLE = 'ADMIN' | 'USER' | 'TEACHER'

export interface UserInterface {
  username: string
  password: string
}

export interface SidebarInterface {
  label: any
  route: { name: string; params?: Record<string, any> }
  exact?: boolean
  child?: boolean
  childrens?: SidebarInterface[]
}

export interface RepositoryInterface {
  _id: string
  createdAt?: Date | string
  updatedAt?: Date | string
}

export interface MetaInterface {
  page: number
  perPage: number
  total: number
}

export interface PaginationInterface<T> {
  items: T[]
  meta: MetaInterface
}

// user
export interface UserInfoInterface extends RepositoryInterface {
  username?: string
  email?: string
  firstName?: string
  lastName?: string
  name?: string
  phoneNumber?: string
  avatar?: string
  roles?: Role
}

// course

export type CourseFormat = 'IELTS' | 'SAT' | 'SEED' | 'EP' | ''

export interface CourseInterface extends RepositoryInterface {
  categoryId?: string
  fullname: string
  shortName?: string
  summary?: string
  format?: CourseFormat
  startDate?: Date | string
  endDate?: Date | string
  status?: number
  thumbnail?: string
  category?: CategoryInterface | string
  sections?: SectionInterface[]
  color?: string
  emailFormat?: string
}

export interface SectionInterface {
  _id: string
  courseId: string
  description?: string
  order?: number
  name: string
  note?: string
  modules?: Array<any>
  status?: string
}

export interface CouresResponsePagination {
  items: CourseInterface[]
  meta: Pagination
}

interface Pagination {
  total: number
  page: number
  perPage: number
}

export interface CategoryInterface extends RepositoryInterface {
  parentId: string
  name: string
  slug: string
}

// question
export interface QuestionInterface extends RepositoryInterface {
  name: string
  questionId?: string
  type: QuestionType
  content: string
  tags: TagsInterface[]
  point?: number
  keys?: string[]
  answers?: AnswerInterface[]
  parentId?: string
  childQuestions?: QuestionInterface[]
  index?: number
}

export interface AnswerInterface extends RepositoryInterface {
  content: string
  isCorrect: boolean
  comment?: string
  key?: string
  type: QuestionType
  questionId?: string
}

export interface TagsInterface extends RepositoryInterface {
  name: string
  slus: string
}

export enum QuestionType {
  MUTILCHOICE = 'multiple_choice_question',
  TRUE_FALSE = 'true_false_question',
  SHORT_ANSWER = 'short_answer_question',
  FILL_IN_MULTIPLE = 'fill_in_multiple_blanks_question',
  MUTILPLE_ANSWERS = 'multiple_answers_question',
  MUTILPLE_DROPDOWN = 'multiple_dropdowns_question',
  // MATCHING_QUESTION = 'matching_question',
  // NUMERICAL = 'numerical_question',
  // CALCULATED = 'calculated_question',
  ESSAY = 'essay_question',
  FILE_UPLOAD = 'file_upload_question',
  TEXT_ONLY = 'text_only_question'
}

export interface Role extends RepositoryInterface {
  name: string
  slug: string
  scope?: string
}

export interface AssignMemberRequest {
  role: string
  ids: Array<string>
}

export interface ModuleInterface extends RepositoryInterface {
  courseId: string
  sectionId: string
  name: string
  description?: string
  module_type?: ModuleType
  filePath?: string
  fileName?: string
  moduleType?: string
  moduleId?: string
  showResult?: boolean
}

export interface PartInterface extends RepositoryInterface {
  header: string
  note?: string
  order?: number
  numQuestion?: number
  quizId: string
  questions?: QuestionInterface[]
}

export interface QuizInterface extends ModuleInterface {
  timeLimit?: number | string
  condition?: any
  gradeToPass?: number
  attemptAllowed?: number
  numberPart?: number
  materials?: MaterialInterface[]
  parts: PartInterface[]
  startDate?: number
  endDate?: number
}

export enum ModuleType {
  //   ASSIGNMENT = 'assignment',
  QUIZ = 'quiz',
  ATTACHMENT = 'attachment'
  //   WIKI_PAGE = 'wiki_page',
  //   DISCUSSION_TOPIC = 'discussion_topic',
  //   CONTEXT_MODULE = 'context_module_sub_header',
  //   EXTERNAL_URL = 'external_url',
  //   CONTEXT_EXTERNAL = 'context_external_tool'
}

export interface AnswerSubmitInterface {
  [x: string]: string | number | Array<string> | Record<string, string | number>
}

export interface AttemptInterface {
  startTime: number
  status: 'in_process' | string
  finishTime: number
  numberAttempts: number
  attemptId: string | null
}

export interface AttemptResultInterface extends RepositoryInterface {
  grade: number
  lastTimePing?: number
  numberRightAnswers: number
  quizId: string
  status: string
  timeFinish: number
  timeStart: number
  userId: string
  user?: UserInfoInterface
  // userAnswer: string | string[]
  // rightAnswer: string | Record<string, any> | Array<Record<string, any>>
}

export interface UserAnswerInterface extends RepositoryInterface {
  attemptId: string
  comment: string
  grade: number
  isCorrect: boolean
  questionId: string
  quizId: string
  rightAnswer: string | Array<Record<string, any>>
  timeAnswer: number
  userAnswer: string | Array<string>
  userId: string
}

export interface FileResultInterface {
  filename: string
  path: string
}

export interface GradeBookInterface extends RepositoryInterface {
  firstName?: string
  lastName?: string
  email?: string
  username?: string
  phoneNumber?: string
  gradeBook?: GradeBookChildInterface[]
}

export interface GradeBookChildInterface {
  courseId: string
  userId: string
  quizId: string
  quizName: string
  grade: number
  numberQuestions: number
  numberRightAnswers: number
  type: string
}

export type MaterialType = 'audio' | 'file' | 'video' | 'image'
export interface MaterialInterface {
  fileUrl: string
  type: MaterialType
  settings: Record<string, any>
}

export interface MeetingInterface extends RepositoryInterface {
  startTime: number
  name: string
  meetingId: string
}

export interface QuizReviewInterface extends RepositoryInterface {
  courseId: string
  studentId: string
  attemptId: string
  teacherId: string
  quizId: string
  studentName?: string
  status: 'done' | 'pending'
  comment?: string
}
export interface QuizReviewDataInterface {
  quiz: QuizInterface
  total: number
  attempts: QuizReviewInterface[]
}
