import { RouteConfig } from 'vue-router'
import ChildLayout2 from '@/components/ChildLayout2.vue'
import Siderbar from '../components/sidebar/Sidebar.vue'

const memberRouter: RouteConfig[] = [
  {
    path: '/',
    components: {
      sidebar: Siderbar,
      default: ChildLayout2
    },
    children: [
      {
        path: '/',
        name: 'member',
        component: () => import('../views/Member.vue')
      },
      {
        path: 'me',
        name: 'myUser',
        component: () => import('../views/MyUser.vue')
      }
    ]
  }
]

export default memberRouter
