import { RouteConfig } from 'vue-router'

const authRouter: RouteConfig[] = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  }
]

export default authRouter
