import { Module } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import Cookies from 'js-cookie'
import { UserInfoInterface } from '@/interface/BaseInterface'
import { roleModuleConfig, RoleModuleConfig } from '@/utils/role'

export interface SAuth {
  token: string
  refreshToken: string
  user: UserInfoInterface
  roles: RoleModuleConfig
  courseRole: RoleModuleConfig
}

const state: SAuth = {
  token: Cookies.get('access_token') || '',
  refreshToken: Cookies.get('refresh_token') || '',
  user: {
    _id: '',
    username: '',
    name: ''
  },
  roles: roleModuleConfig['MEMBER'],
  courseRole: roleModuleConfig['COURSE_MEMBER']
}

const tAuth: Module<SAuth, unknown> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default tAuth
