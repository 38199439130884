import { AnswerSubmitInterface, UserAnswerInterface } from '@/interface/BaseInterface'
import request from '@/utils/request'

export default class QuizAttempt {
  protected prefix = 'quiz'

  async attemptQuiz(quizId: string) {
    try {
      // const result = await request.post(`${this.prefix}/${quizId}/attempt`)
      const result = await request.post(`${this.prefix}/v2/${quizId}/attempt/resigter`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async quizAttemptStatus(quizId: string) {
    try {
      const result = await request.get(`${this.prefix}/v2/${quizId}/attempt/status`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async save(quizId: string, answers: AnswerSubmitInterface) {
    try {
      const result = await request.post(`${this.prefix}/${quizId}/save`, {
        answers: answers
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async submit(quizId: string, attemptId: string, answers: AnswerSubmitInterface) {
    try {
      const result = await request.post(`${this.prefix}/v2/${quizId}/attempt/${attemptId}/submit`, {
        answers: answers
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async list(quizId: string) {
    try {
      const result = await request.get(`${this.prefix}/${quizId}/attempt`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async allList(quizId: string) {
    try {
      const result = await request.get(`${this.prefix}/${quizId}/all-attempt`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async listUserNotAttmept(courseId: string, quizId: string) {
    try {
      const result = await request.get(`${this.prefix}/list-user-not-attempt`, {
        params: {
          quizId,
          courseId
        }
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async sendEmailForUserNotAttmept(courseId: string, quizId: string) {
    try {
      const result = await request.get(`${this.prefix}/not-attempt/send-email`, {
        params: {
          quizId,
          courseId
        }
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async allListResult(quizId: string) {
    try {
      const result = await request.get(`${this.prefix}/${quizId}/all-attempt/result`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async attemptDetail(quizId: string, attemptId: string): Promise<Array<UserAnswerInterface>> {
    try {
      const result = await request.get(`${this.prefix}/${quizId}/${attemptId}/answers`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async attemptDetailByUser(quizId: string, attemptId: string, userId: string): Promise<Array<UserAnswerInterface>> {
    try {
      const result = await request.get(`${this.prefix}/${quizId}/${attemptId}/${userId}/answers`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async attemptComment(quizId: string, attemptId: string, questionId: string, comment: string, grade?: number) {
    try {
      const result = await request.put(`${this.prefix}/${quizId}/${attemptId}/comment/${questionId}`, {
        comment: comment,
        grade: grade
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
