import { RouteConfig } from 'vue-router'
import Siderbar from '../components/sidebar/Sidebar.vue'
import QuestionLayout from '../views/QuestionLayout.vue'

const questionRouter: RouteConfig[] = [
  {
    path: '/',
    components: {
      sidebar: Siderbar,
      default: QuestionLayout
    },
    children: [
      {
        path: '/',
        name: 'questionBank',
        component: () => import('../views/QuestionBank.vue')
      },
      {
        path: 'create',
        name: 'questionBankCreate',
        component: () => import('../views/CreateQuestionView.vue')
      },
      {
        path: ':questionId/edit',
        name: 'questionBankEdit',
        component: () => import('../views/CreateQuestionView.vue')
      }
    ]
  }
]

export default questionRouter
