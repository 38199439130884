import { QuizInterface } from '../../../interface/BaseInterface'
import request from '@/utils/request'
import { BaseApiInterface } from '../../../interface/BaseApiInterface'

export default class QuizApi implements BaseApiInterface<QuizInterface> {
  protected prefix = 'quiz'
  async all(params?: Record<string, any>): Promise<QuizInterface[]> {
    try {
      const result = await request.get(`${this.prefix}`, {
        params: params
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getById(id: string): Promise<QuizInterface> {
    try {
      const result = await request.get(`${this.prefix}/${id}`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async create(params?: Record<string, any>): Promise<any> {
    try {
      const result = await request.post(`${this.prefix}`, params)
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async update(id: string, params?: Record<string, any>): Promise<any> {
    try {
      delete params?.parts
      const result = await request.put(`${this.prefix}/${id}`, params)
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async delete(id: string): Promise<any> {
    try {
      await request.delete(`${this.prefix}/${id}`)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getQuestion(id: string): Promise<QuizInterface> {
    const result = await request.get(`${this.prefix}/${id}/questions`)
    return result.data.data
  }

  async getQuestionOverview(id: string): Promise<QuizInterface> {
    try {
      const result = await request.get(`${this.prefix}/${id}/questions/overview`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addPart(quizId: string, params: Record<string, any>) {
    try {
      const result = await request.post(`${this.prefix}/${quizId}/part`, params)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updatePart(quizId: string, partId: string, params: Record<string, any>) {
    try {
      const result = await request.put(`${this.prefix}/${quizId}/part/${partId}`, params)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async removePart(quizId: string, partId: string) {
    try {
      const result = await request.delete(`${this.prefix}/${quizId}/part/${partId}`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async createQuizQuestion(quizId: string, partId: string) {
    try {
      const result = await request.post(`${this.prefix}/${quizId}/${partId}/question`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async deleteQuestion(quizId: string, partId: string, questionId: string) {
    try {
      await request.delete(`${this.prefix}/${quizId}/${partId}/question/${questionId}`)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getCloneList(format?: string): Promise<QuizInterface[]> {
    try {
      const result = await request.get(`${this.prefix}/clone/list`, {
        params: {
          format: format
        }
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async cloneQuiz(courseId: string, sectionId: string, quizId: string): Promise<QuizInterface> {
    try {
      const result = await request.post(`${this.prefix}/clone/${quizId}/to/${courseId}/section/${sectionId}`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getLogs(): Promise<any> {
    try {
      const result = await request.get(`${this.prefix}/list-log`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
