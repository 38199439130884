
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { indexOf, join, filter } from 'lodash'
  import { fileAccept, audioAccept, videoAccept, imageAccept, API_URL } from '@/config'
  import getApi, { UploadApi } from '@/api'
  import { FileResultInterface } from '@/interface/BaseInterface'

  type InputType = 'all' | 'file' | 'audio' | 'image' | 'video'

  const uploadApi: UploadApi = getApi('upload')

  @Component
  export default class BaseUpload extends Vue {
    @Prop({
      required: false,
      type: Array,
      default: () => {
        return ['all']
      }
    })
    type!: InputType[]

    @Prop({ required: false, type: Boolean, default: true }) mutiple!: boolean
    @Prop({ required: false, type: Number, default: 1 }) limit!: number

    fileList: any[] = []

    get getAttempt() {
      if (indexOf(this.type, 'all') !== -1) {
        return join([...fileAccept, ...imageAccept, ...audioAccept, ...videoAccept], ',')
      }
      let result: string[] = []
      if (indexOf(this.type, 'file') !== -1) {
        result = [...result, ...fileAccept]
      }
      if (indexOf(this.type, 'image') !== -1) {
        result = [...result, ...imageAccept]
      }
      if (indexOf(this.type, 'video') !== -1) {
        result = [...result, ...videoAccept]
      }
      if (indexOf(this.type, 'audio') !== -1) {
        result = [...result, ...audioAccept]
      }
      return join(result, ',')
    }

    get getAction() {
      return `${API_URL}/media/upload`
    }

    async submit() {
      try {
        // @ts-ignore
        this.$refs.upload.clearFiles()
      } catch (error) {
        // @ts-ignore
        this.$refs.upload.clearFiles()
        return Promise.reject(error)
      }
    }

    handleSuccess(response: FileResultInterface[]) {
      this.$emit('success', response)
      // @ts-ignore
      this.$refs.upload.clearFiles()
    }

    handlePreview(data: any) {
      console.log(data)
    }

    // changeImgFile(file: any, fileList: any) {
    changeImgFile(file: any) {
      if (file.size > 50 * 1024 * 1024) {
        this.$notify.warning(this.$t('notifi.file.maxsize') as string)
        return
      }
      file.type = file.raw.type
      this.fileList.push(file)
    }

    async handleUpload(param: any) {
      try {
        const result = await uploadApi.upload(param.file, param.onProgress)
        param.onSuccess(result)
      } catch (error) {
        param.onError(error)
      }
    }

    handleError(err: any) {
      this.$message.error('Upload failed.')
      this.$emit('error', err)
      // @ts-ignore
      this.$refs.upload.clearFiles()
    }

    handleRemove(file: any) {
      this.fileList = filter(this.fileList, value => {
        return value.uid !== file.uid
      })
    }
  }
