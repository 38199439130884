import { Role } from './../../../../interface/BaseInterface'
import { UserInfoInterface, UserInterface } from '@/interface/BaseInterface'
import { ActionTree } from 'vuex'
import { SAuth } from '.'
import Cookies from 'js-cookie'
import getApi from '@/api'

const authApi = getApi('auth')

const actions: ActionTree<SAuth, unknown> = {
  async login({ commit }, data: UserInterface) {
    try {
      const result = await authApi.login(data)
      Cookies.set('access_token', result.token)
      Cookies.set('refresh_token', result.refreshToken || '')
      commit('UPDATE_TOKEN', {
        token: result.token,
        refreshToken: result.refreshToken || ''
      })
      return Promise.resolve(result)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getMyInfo({ commit }) {
    try {
      const result = await authApi.getMyInfo()
      // const resultString = Cookies.get('user_info')
      // const result: UserInfoInterface = resultString && resultString !== 'undefined' ? JSON.parse(resultString) : { username: '', name: '' }
      commit('UPDATE_INFO', result)
      return Promise.resolve(result)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async refreshToken({ commit }) {
    console.log('afkhdabfdhsbfhjkdbfhkjbdskhjcvbsadkhjbcdhjsbchj')
    const result = await authApi.refreshToken()
    Cookies.set('access_token', result.token)
    Cookies.set('refresh_token', result.refreshToken || '')
    commit('UPDATE_TOKEN', {
      token: result.token,
      refreshToken: result.refreshToken || ''
    })
    return Promise.resolve(result)
  },
  updateUserInfo({ commit }, user: UserInfoInterface) {
    Cookies.set('user_info', JSON.stringify(user))
    commit('UPDATE_INFO', user)
  },
  logout({ commit }) {
    Cookies.remove('access_token')
    Cookies.remove('refresh_token')
    authApi.logout()
    commit('LOGOUT')
  },
  clearStore({ commit }) {
    Cookies.remove('access_token')
    Cookies.remove('refresh_token')
    commit('LOGOUT')
  },
  setRoleCourse({ commit }, role: Role) {
    commit('SET_COURSE_ROLE', role)
  }
}

export default actions
