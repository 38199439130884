
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { namespace } from 'vuex-class'
  import { findIndex } from 'lodash'
  import { SPopupBase } from '@/store/base'

  const base = namespace('base')

  @Component
  export default class BasePopupFullscreen extends Vue {
    @Prop({ required: true, type: String }) module!: string
    @Prop({ required: false, type: String, default: '600px' }) width!: string
    @Prop({ required: false, type: String, default: 'none' }) type!: 'none' | 'add' | 'edit'
    @Prop({ required: false, type: String, default: '' }) popupClass!: string
    @Prop({
      required: false,
      type: Function,
      default: () => {
        console.log('open')
      }
    })
    open!: () => void
    @Prop({
      required: false,
      type: Function,
      default: () => {
        console.log('close')
      }
    })
    close!: () => void
    @Prop({
      required: false,
      type: Function,
      default: () => {
        console.log('submit')
      }
    })
    submit!: () => Promise<any>

    @base.State('popup')
    popup!: string[]
    @base.Action('setOpenPopup') setOpenPopup!: (data: SPopupBase) => void

    isLoading = false

    get isOpen() {
      return (
        findIndex(this.popup, value => {
          return value === this.module
        }) !== -1
      )
    }

    set isOpen(value: boolean) {
      this.setOpenPopup({
        isOpen: value,
        module: this.module
      })
    }

    handleOpen() {
      this.isLoading = false
      this.open()
    }

    handleClose() {
      this.close()
    }

    async handleSubmit() {
      try {
        if (this.isLoading) {
          return
        }
        this.isLoading = true
        await this.submit()
        this.isLoading = false
        this.isOpen = false
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    }
  }
