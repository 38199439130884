
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import 'tui-calendar/dist/tui-calendar.css'
  import { Calendar } from '@toast-ui/vue-calendar'

  @Component({ components: { Calendar } })
  export default class BaseCalendar extends Vue {
    @Prop({
      required: false,
      type: Array,
      default: () => {
        return []
      }
    })
    schedules!: Array<Record<string, any>>

    @Prop({
      required: false,
      type: Array,
      default: () => {
        return []
      }
    })
    calendars!: Array<Record<string, any>>

    @Prop({
      required: false,
      type: Array,
      default: () => {
        return [
          {
            timezoneOffset: 540,
            displayLabel: 'GMT+09:00',
            tooltip: 'Seoul'
          },
          {
            timezoneOffset: -420,
            displayLabel: 'GMT-08:00',
            tooltip: 'Los Angeles'
          }
        ]
      }
    })
    timezones!: Array<Record<string, any>>

    @Prop({
      required: false,
      type: Object,
      default: () => {
        return {
          startDayOfWeek: 1
        }
      }
    })
    week!: Record<string, any>

    @Prop({
      required: false,
      type: Object,
      default: () => {
        return {
          'month.dayname.height': '30px',
          // 'month.dayname.borderLeft': '1px solid #ff0000',
          'month.dayname.textAlign': 'center',
          'week.today.color': '#333',
          'week.daygridLeft.width': '100px',
          'week.timegridLeft.width': '100px'
        }
      }
    })
    theme!: Record<string, any>

    @Prop({
      required: false,
      type: Object,
      default: () => {
        return {}
      }
    })
    template!: Record<string, any>

    @Prop({ required: false, type: String, default: 'week' }) view!: string
    @Prop({ required: false, type: Boolean, default: false }) isReadOnly!: boolean
    @Prop({ required: false, type: Boolean, default: false }) disableClick!: boolean
    @Prop({ required: false, type: Boolean, default: false }) disableDblClick!: boolean
    @Prop({ required: false, type: Boolean, default: false }) useCreationPopup!: boolean
    @Prop({ required: false, type: Boolean, default: true }) useDetailPopup!: boolean
    @Prop({ required: false, type: Boolean || Array, default: false }) taskView!: boolean | Array<Record<string, any>>
    @Prop({ required: false, type: Boolean || Array, default: true }) scheduleView!: boolean | Array<Record<string, any>>

    weekPicker = new Date()

    onAfterRenderSchedule(e: any) {
      console.log('onAfterRenderSchedule(e: any)', e)
      this.$emit('onAfterRenderSchedule', e)
      // implement your code
    }
    onBeforeCreateSchedule(e: any) {
      console.log('onBeforeCreateSchedule(e: any)', e)
      this.$emit('onBeforeCreateSchedule', e)
      // implement your code
    }
    onBeforeDeleteSchedule(e: any) {
      console.log('onBeforeDeleteSchedule(e: any)', e)
      this.$emit('onBeforeDeleteSchedule', e)
      // implement your code
    }
    onBeforeUpdateSchedule(e: any) {
      console.log('onBeforeUpdateSchedule(e: any)', e)
      this.$emit('onBeforeUpdateSchedule', e)
      // implement your code
    }
    onClickDayname(e: any) {
      console.log('onClickDayname(e: any)', e)
      this.$emit('onClickDayname', e)
      // implement your code
    }
    onClickSchedule(e: any) {
      console.log('onClickSchedule(e: any)', e)
      this.$emit('onClickSchedule', e)
      // implement your code
    }
    onClickTimezonesCollapseBtn(e: any) {
      console.log('onClickTimezonesCollapseBtn(e: any)', e)
      this.$emit('onClickTimezonesCollapseBtn', e)
      // implement your code
    }

    onChangeDate() {
      if (this.$refs.tuiCalendar) {
        //@ts-ignore
        this.$refs.tuiCalendar.invoke('setDate', this.weekPicker)
      }
      this.$emit('onTimeChange', this.weekPicker)
    }

    goToday() {
      this.weekPicker = new Date()
      if (this.$refs.tuiCalendar) {
        //@ts-ignore
        this.$refs.tuiCalendar.invoke('setDate', this.weekPicker)
      }
      this.$emit('onTimeChange', this.weekPicker)
    }
  }
