import authRouter from '@/modules/auth/route'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Layout from '../components/Layout.vue'
import ChildLayout from '../components/ChildLayout.vue'
import store from '../store'
import memberRouter from '@/modules/member/route'
import courseRouter from '@/modules/course/route'
import questionRouter from '@/modules/questions/route'
import courseDetailRouter from '@/modules/courseDetail/route'
import meetingRouter from '@/modules/meetings/route'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        redirect: 'dashboard'
      },
      {
        path: 'dashboard',
        component: ChildLayout,
        children: courseRouter
      },
      {
        path: 'question-bank',
        component: ChildLayout,
        children: questionRouter
      },
      {
        path: 'course',
        component: ChildLayout,
        children: courseDetailRouter
      },
      {
        path: 'member',
        component: ChildLayout,
        children: memberRouter
      },
      {
        path: 'meeting',
        component: ChildLayout,
        children: meetingRouter
      }
    ]
  },
  ...authRouter,
  {
    path: '*',
    name: 'notfound',
    component: () => import('@/views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, _from, next) => {
  if (to.name !== 'login' && !store.getters['tAuth/isLogin']) {
    router.push({ name: 'login' })
  }
  next()
})

export default router
