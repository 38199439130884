
  import { Component, Vue, Prop } from 'vue-property-decorator'

  @Component
  export default class BaseTitle extends Vue {
    @Prop({ required: true, type: String }) title!: string
    @Prop({ required: false, type: Number || String, default: 25 }) size!: number | string

    get getSize() {
      return {
        fontSize: `${this.size}px`
      }
    }
  }
