
  import { Vue, Component } from 'vue-property-decorator'

  @Component
  export default class BaseFilter extends Vue {
    search = ''
    timeCount: any = ''

    handleSearchChange() {
      clearTimeout(this.timeCount)
      this.timeCount = setTimeout(() => {
        this.$emit('filter', {
          search: this.search
        })
      }, 300)
    }
  }
