import { CourseInterface, CouresResponsePagination } from '@/interface/BaseInterface'
import request from '@/utils/request'
import { BaseApiInterface } from './../../../interface/BaseApiInterface'

export default class CourseApi implements BaseApiInterface<CourseInterface> {
  protected prefix = 'course'
  async all(params?: Record<string, any>): Promise<CourseInterface[]> {
    try {
      const result = await request.get(`${this.prefix}`, {
        params: params
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async list(params?: Record<string, any>): Promise<CouresResponsePagination> {
    try {
      const result = await request.get(`${this.prefix}`, {
        params: params
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getById(id: string): Promise<CourseInterface> {
    try {
      const result = await request.get(`${this.prefix}/${id}`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async create(params?: Record<string, any>): Promise<any> {
    try {
      const result = await request.post(`${this.prefix}`, params)
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async update(id: string, params?: Record<string, any>): Promise<any> {
    try {
      const result = await request.put(`${this.prefix}/${id}`, params)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async delete(id: string): Promise<any> {
    try {
      await request.delete(`${this.prefix}/${id}`)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async myCourse(params?: Record<string, any>): Promise<CourseInterface[]> {
    try {
      const result = await request.get(`${this.prefix}/my-course`, {
        params: params
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getCourseRole(id: string) {
    try {
      const result = await request.get(`${this.prefix}/${id}/role`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
