import { PaginationInterface, UserInfoInterface } from './../../../interface/BaseInterface'
import request from '@/utils/request'

interface SearchParams {
  courseId: string
  searchText?: string
  orderBy?: string
  orderDirection?: 'DESC' | 'ASC' | string
  page: number
  perPage: number
}

export default class CourseMemberApi {
  static async list(params: SearchParams): Promise<PaginationInterface<UserInfoInterface>> {
    try {
      const result = await request.get(`course/${params.courseId}/members`, {
        params: params
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async assignMembers(courseId: string, data: any) {
    try {
      const result = await request.post(`course/${courseId}/assign`, data)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async detachMember(courseId: string, userId: string) {
    try {
      const result = await request.post(`course/${courseId}/members/${userId}/detach`)
      return Promise.resolve(result)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
