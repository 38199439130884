
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import sanitizeHtml from 'sanitize-html'

  @Component
  export default class BaseEditorPreview extends Vue {
    @Prop({ required: false, type: String, default: '' }) text!: string

    get getText() {
      return sanitizeHtml(this.text, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
        allowedAttributes: {
          '*': ['style', 'href', 'src', 'class', 'data-*', 'alt', 'center', 'bgcolor', 'width', 'height']
        },
        transformTags: {
          img: function(tagName, attribs) {
            return {
              tagName: 'img',
              attribs: {
                ...attribs,
                src: attribs.src?.replace(/^http:\/\//i, 'https://')
              }
            }
          }
        }
      })
    }
  }
