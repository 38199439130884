import { CourseInterface, ModuleInterface, ModuleType } from './../../../../interface/BaseInterface'
import { Module } from 'vuex'
import actions from './actions'
import mutations from './mutations'

export interface SCourseDetail {
  filePreview: ModuleInterface
  courseActive: CourseInterface
}

const state: SCourseDetail = {
  filePreview: {
    _id: '',
    courseId: '',
    sectionId: '',
    name: '',
    description: '',
    module_type: ModuleType.ATTACHMENT
  },
  courseActive: {
    _id: '',
    fullname: ''
  }
}

const tCourseDetail: Module<SCourseDetail, unknown> = {
  namespaced: true,
  state,
  actions,
  mutations
}

export default tCourseDetail
