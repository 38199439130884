import { API_URL } from '@/config'
import axios from 'axios'
import Cookies from 'js-cookie'
import store from '../store/index'
import { Message } from 'element-ui'
import { includes } from 'lodash'

const request = axios.create({
  baseURL: API_URL,
  withCredentials: false
})

request.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded'

request.defaults.headers.common['Authorization'] = Cookies.get('access_token') !== 'undefined' ? 'Bearer ' + Cookies.get('access_token') : ''

request.interceptors.response.use(
  async response => {
    if (response.data.code === 401) {
      if (includes(response.config.url, 'refresh')) throw response.data
      await store.dispatch('tAuth/refreshToken')
      return axios.request(response.config)
    } else if (response.data.code !== 200) {
      if (!response.data.message) {
        Message.error('Cannot connect to server')
      }
      throw response.data
    }
    return response
  },
  async error => {
    if (!error.response || error.response.status >= 500) {
      // error.message = 'Không thể kết nối đến máy chủ'
      error.message = 'Cannot connect to server'
      Message.error(error.message)
      return Promise.reject(error)
    }

    if (error.response.status < 500) {
      if (error.response.status === 401) {
        if (includes(error.config.url, 'refresh')) return Promise.reject(error)
        try {
          await store.dispatch('tAuth/refreshToken')
          return axios.request(error.config)
        } catch (error) {
          return Promise.reject(error)
        }
      } else if (error.response.status === 400) {
        if (!error.response.data.message) {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const listErrors = <Array<string[]>>Object.keys(error.response.data).map((key: any) => {
            return error.response.data[key]
          })

          listErrors.forEach(value => {
            value.forEach((err: string) => {
              setTimeout(() => {
                Message.error(err)
              }, 100)
            })
          })
        } else {
          Message.error(error.response.data.message)
        }
      }
      throw error
    }

    throw error
  }
)

export default request
