import { RouteConfig } from 'vue-router'
import ChildLayout2 from '@/components/ChildLayout2.vue'
import Siderbar from '../components/sidebar/Sidebar.vue'

const meetingRouter: RouteConfig[] = [
  {
    path: '/',
    components: {
      sidebar: Siderbar,
      default: ChildLayout2
    },
    children: [
      {
        path: '/',
        name: 'meeting',
        component: () => import('../views/MeetingsPage.vue')
      },
      {
        path: 'history',
        name: 'meetingHistory',
        component: () => import('../views/MeetingsPage.vue')
      },
      {
        path: 'create',
        name: 'meetingCreate',
        component: () => import('../views/meetings/MettingForm.vue')
      },
      {
        path: 'edit/:id',
        name: 'meetingEdit',
        component: () => import('../views/meetings/MettingForm.vue')
      },
      {
        path: 'members',
        name: 'meetingUsers',
        component: () => import('../views/UsersPage.vue')
      },
      {
        path: 'setting',
        name: 'meetingSetting',
        component: () => import('../views/SettingsPage.vue')
      },
      {
        path: 'schedules',
        name: 'meetingSchedules',
        component: () => import('../views/SchedulePage.vue')
      },
      {
        path: 'admin',
        name: 'meetingAdmin',
        component: () => import('../views/AdminPage.vue')
      }
    ]
  }
]

export default meetingRouter
