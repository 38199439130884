import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import * as filters from './utils/filters'
// import * as Sentry from '@sentry/vue'
// import { Integrations } from '@sentry/tracing'

// @ts-ignore
import enLocale from 'element-ui/lib/locale/lang/en'

import './assets/styles/index.scss'
import RoleMixin from './mixins/roleMixin'
import { forEach } from 'lodash'
import components from './components/base'
import './utils/unicons'
import GlobalMixin from './mixins/globalMixin'

Vue.config.productionTip = false

Vue.use(ElementUI, {
  locale: enLocale
  // i18n: (key: string, value: unknown[]) => i18n.t(key, value)
})

// mixins
Vue.mixin(RoleMixin)
Vue.mixin(GlobalMixin)

//filter
forEach(filters, (value, key) => {
  Vue.filter(key, value)
})

// register base component
forEach(components, (value, key) => {
  Vue.component(key, value)
})

//sentry
// Sentry.init({
//   Vue,
//   dsn: 'https://16790c52e03e4029ad9ea06c23a2ca0e@o982096.ingest.sentry.io/5936838',
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ['localhost', 'lms.thangnguyen.xyz', 'lms.iseed.vn', /^\//]
//     })
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0
// })

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
