
  import { Component, Vue } from 'vue-property-decorator'
  import { includes } from 'lodash'
  import { MODE } from '@/config'

  @Component
  export default class NavMenu extends Vue {
    isCollapse = false

    mode = MODE

    get getCourseActive() {
      return includes(this.$route.path, 'course') && !includes(this.$route.path, 'dashboard')
    }

    get getMemberActive() {
      return includes(this.$route.path, 'member') && !includes(this.$route.path, 'course') && !includes(this.$route.path, 'meeting')
    }

    goToHome() {
      this.$router.push({ name: 'home' })
    }

    goToMember() {
      if (this.checkRole(['view'], 'USERS')) {
        this.$router.push({ name: 'member' })
      } else {
        this.$router.push({ name: 'myUser' })
      }
    }
    handleClose() {
      console.log('close')
    }
  }
