import { GetterTree } from 'vuex'
import { SAuth } from '.'
import { mapRole } from '@/utils/role'

const getters: GetterTree<SAuth, any> = {
  isLogin: state => {
    return !!state.token && state.token !== '' && state.token !== 'undefined'
  },
  getRole: state => {
    return mapRole(state.roles, state.courseRole)
  }
}

export default getters
