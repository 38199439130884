import { UserInterface } from '../../../interface/BaseInterface'
import request from '@/utils/request'

export default class AuthApi {
  static prefix = 'auth'
  static async login(data: UserInterface) {
    try {
      const result = await request.post(`${this.prefix}/login`, {
        username: data.username,
        password: data.password
      })
      const { token, user } = result.data.data
      request.defaults.headers.common['Authorization'] = 'Bearer ' + token
      return Promise.resolve({ token, user })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async refreshToken() {
    try {
      const result = await request.post(`${this.prefix}/refresh`)
      const { token, user } = result.data.data
      request.defaults.headers.common['Authorization'] = 'Bearer ' + token
      return Promise.resolve({ token, user })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getMyInfo() {
    try {
      const result = await request.get(`user/me`)
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async logout() {
    try {
      await request.post(`${this.prefix}/logout`)
      delete request.defaults.headers.common['Authorization']
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async changePassword(oldPass: string, newPass: string) {
    try {
      const result = await request.post(`${this.prefix}/change-password `, {
        oldPassword: oldPass,
        newPassword: newPass
      })
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
