
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { MetaInterface } from '@/interface/BaseInterface'

  @Component
  export default class BasePagination extends Vue {
    @Prop({ required: true, type: Object }) meta!: MetaInterface

    get isShowPagin() {
      return this.meta.total > this.meta.perPage
    }

    handleSizeChange(val: number) {
      this.$emit('sizeChange', val)
    }

    handleCurrentChange(val: number) {
      this.$emit('pageChange', val)
    }
  }
