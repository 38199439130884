
  import { Vue, Component } from 'vue-property-decorator'
  import { SidebarInterface } from '@/interface/BaseInterface'

  @Component
  export default class Sidebar extends Vue {
    menu: SidebarInterface[] = []

    created() {
      if (this.checkRole(['view'], 'USERS')) {
        this.menu = [
          {
            label: this.$t('profile'),
            route: { name: 'myUser' },
            exact: false
          },
          {
            label: this.$t('members'),
            route: { name: 'member' },
            exact: true
          }
        ]
      } else {
        this.menu = [
          {
            label: this.$t('profile'),
            route: { name: 'myUser' },
            exact: false
          }
        ]
      }
    }
  }
