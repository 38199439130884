
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { SidebarInterface } from '@/interface/BaseInterface'

  @Component
  export default class BaseSidebar extends Vue {
    @Prop({ required: true, type: Array }) menu!: SidebarInterface[]

    get activeIndex() {
      return this.$route.name || ''
    }
  }
