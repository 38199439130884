import { forEach } from 'lodash'
import AuthApi from '@/modules/auth/api/AuthApi'
import CategoryApi from '@/modules/course/api/CategoryApi'
import CourseApi from '@/modules/course/api/CourseApi'
import MemberApi from '@/modules/member/api/memberApi'
import QuestionApi from '@/modules/questions/api/questionApi'
import TagApi from '@/modules/questions/api/tagApi'
import CourseMemberApi from '@/modules/courseDetail/api/CourseMemberApi'
import SectionApi from '@/modules/courseDetail/api/sectionApi'
import QuizApi from '@/modules/courseDetail/api/quizApi'
import QuizAttempt from '@/modules/courseDetail/api/quizAttempt'
import QuizReview from '@/modules/courseDetail/api/quizReview'
import request from '@/utils/request'
import { FileResultInterface } from '@/interface/BaseInterface'
import { GradeBookApi } from '@/modules/courseDetail/api/GradeBookApi'
import CourseFileApi from '@/modules/courseDetail/api/courseFileApi'
import MeetingApi from '@/modules/meetings/api/mettingApi'
import CourseEmailApi from '@/modules/courseDetail/api/courseEmailApi'

export type ApiType =
  | 'auth'
  | 'member'
  | 'course'
  | 'category'
  | 'question'
  | 'tag'
  | 'course_member'
  | 'section'
  | 'quiz'
  | 'quizAttept'
  | 'upload'
  | 'gradeBook'
  | 'quizAttempt'
  | 'courseFileApi'
  | 'meetingApi'
  | 'quizReview'
  | 'courseEmail'

export class UploadApi {
  public async upload(file: File, process: any) {
    try {
      const fromData = new FormData()
      fromData.append('name', file.name)
      fromData.append('files', file)
      const result = await request.post('media/upload', fromData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: process
      })
      const files: FileResultInterface[] = []
      forEach(result.data.data, value => {
        files.push({
          filename: value.filename,
          path: value.path
          // path: `http://cdn.nguyenquangbach.com/${value.path}`
        })
      })
      return Promise.resolve(files)
    } catch (error) {
      return Promise.resolve(error)
    }
  }
}

export default function getApi(name: ApiType) {
  if (name === 'auth') {
    return AuthApi
  } else if (name === 'member') {
    return new MemberApi()
  } else if (name === 'course') {
    return new CourseApi()
  } else if (name === 'category') {
    return new CategoryApi()
  } else if (name === 'question') {
    return new QuestionApi()
  } else if (name === 'tag') {
    return new TagApi()
  } else if (name === 'course_member') {
    return CourseMemberApi
  } else if (name === 'section') {
    return new SectionApi()
  } else if (name === 'quiz') {
    return new QuizApi()
  } else if (name === 'quizAttempt') {
    return new QuizAttempt()
  } else if (name === 'upload') {
    return new UploadApi()
  } else if (name === 'gradeBook') {
    return new GradeBookApi()
  } else if (name === 'courseFileApi') {
    return new CourseFileApi()
  } else if (name === 'meetingApi') {
    return new MeetingApi()
  } else if (name === 'quizReview') {
    return new QuizReview()
  } else if (name === 'courseEmail') {
    return new CourseEmailApi()
  }

  const result: any = {}

  return result
}
